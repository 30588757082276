<template>
  <SuccessBanner
    @bannerTimer="activeBanner = false"
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="flex flex-col p-10">
    <div
      :class="[
        activeBanner && isSuccess
          ? `border-green-400 border-2`
          : activeBanner && !isSuccess
          ? `border-red-400 border-2`
          : '',
        `-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0`,
      ]"
    >
      <form
        @submit.prevent="onSubmit('Host updated successfully')"
        class="space-y-8 divide-y divide-gray-200 bg-white shadow-md pr-6 pl-6 pb-2 rounded"
      >
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="flex justify-between">
              <h3 class="text-lg leading-6 font-medium body__header--text">
                Host {{ host.code }}
              </h3>
              <a href="javascript:void(0)" @click="showForm = !showForm">
                <svg
                  v-if="!showForm"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <svg
                  v-if="showForm"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </a>
            </div>
            <div v-if="showForm" class="space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="client"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Client
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    readonly
                    v-model="form.client"
                    type="text"
                    name="client"
                    id="client"
                    autocomplete="client"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    required=""
                    v-model="form.name"
                    id="name"
                    name="name"
                    type="text"
                    autocomplete="name"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="code"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Code
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    required=""
                    v-model="form.code"
                    id="code"
                    name="code"
                    type="text"
                    autocomplete="code"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="address"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Address
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    required=""
                    v-model="form.address"
                    id="address"
                    name="address"
                    type="text"
                    autocomplete="name"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="vlan"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  VLAN
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.vlan"
                    type="text"
                    name="vlan"
                    id="vlan"
                    autocomplete="vlan"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="config"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  config
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.config"
                    type="text"
                    name="config"
                    id="config"
                    autocomplete="config"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="infrastructure"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  infrastructure
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.infrastructure"
                    id="infrastructure"
                    name="infrastructure"
                    autocomplete="infrastructure"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="infrastructure in infrastructures"
                      :key="infrastructure.id"
                      :value="infrastructure.id"
                      >{{ infrastructure.name }}</option
                    >
                  </select>
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="provider"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  provider
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.provider"
                    id="provider"
                    name="provider"
                    autocomplete="provider"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="provider in providers"
                      :key="provider.id"
                      :value="provider.id"
                      >{{ provider.name }}</option
                    >
                  </select>
                </div>
              </div>

              <!-- <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="nic-id"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  nic ID
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.nicId"
                    id="nic-id"
                    name="nic-id"
                    autocomplete="nic-id"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option v-for="nic in nics" :key="nic.id" :value="nic.id">{{
                      nic.name
                    }}</option>
                  </select>
                </div>
              </div> -->

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="shi"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  SHI
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.shi"
                    id="shi"
                    name="shi"
                    type="text"
                    autocomplete="shi"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="data-center"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  data center
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.dataCenter"
                    id="data-center"
                    name="data-center"
                    autocomplete="data-center"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="dataCenter in dataCenters"
                      :key="dataCenter.id"
                      :value="dataCenter.id"
                      >{{ dataCenter.name }}</option
                    >
                  </select>
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="service-type"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Service type
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.serviceType"
                    id="service-type"
                    name="service-type"
                    autocomplete="service-type"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="serviceType in serviceTypes"
                      :key="serviceType.id"
                      :value="serviceType.id"
                      >{{ serviceType.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="host-type"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Host type
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.hostType"
                    id="host-type"
                    name="host-type"
                    autocomplete="host-type"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="hostType in hostTypes"
                      :key="hostType.id"
                      :value="hostType.id"
                      >{{ hostType.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="host-role"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Host Role
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.hostRole"
                    id="host-role"
                    name="host-role"
                    autocomplete="host-role"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="hostRole in hostRoles"
                      :key="hostRole.id"
                      :value="hostRole.id"
                      >{{ hostRole.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="function"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  function
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.function"
                    id="function"
                    name="function"
                    autocomplete="function"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="hostFunction in hostFunctions"
                      :key="hostFunction.id"
                      :value="hostFunction.id"
                      >{{ hostFunction.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="shell-type"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Shell type
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.shellType"
                    id="shell-type"
                    name="shell-type"
                    autocomplete="shell-type"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="shellType in shellTypes"
                      :key="shellType.id"
                      :value="shellType.id"
                      >{{ shellType.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="operational-system"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Operational System
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.operationalSystem"
                    id="operational-system"
                    name="operational-system"
                    autocomplete="operational-system"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="operationalSystem in operationalSystems"
                      :key="operationalSystem.id"
                      :value="operationalSystem.id"
                      >{{ operationalSystem.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="installation"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Installation
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.installation"
                    id="installation"
                    name="installation"
                    type="text"
                    autocomplete="installation"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="outsourced"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  managed
                </label>
                <Switch
                  id="outsourced"
                  v-model="form.outsourced"
                  class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    class="pointer-events-none absolute bg-white w-full h-full rounded-md"
                  />
                  <span
                    aria-hidden="true"
                    :class="[
                      form.outsourced ? 'bg-indigo-600' : 'bg-gray-200',
                      'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
                    ]"
                  />
                  <span
                    aria-hidden="true"
                    :class="[
                      form.outsourced ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
                    ]"
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end" v-if="showForm">
            <!-- <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button> -->
            <button
              type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md body__button text-white"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      :class="[
        activeBanner && isSuccess
          ? `border-green-400 border-2`
          : activeBanner && !isSuccess
          ? `border-red-400 border-2`
          : '',
        `header overflow-hidden shadow rounded-lg mt-5`,
      ]"
    >
      <div
        class="text-lg leading-6 font-medium px-4 py-5 sm:px-6 body__header--text"
      >
        Installed Softwares
      </div>

      <div class="px-4 py-5 sm:p-6 inline-flex">
        <label
          class="block text-left"
          style="max-width: 500px; min-height: auto"
        >
          <span class="text-gray-700">Select softwares</span>
          <select
            v-model="addSoftwares"
            class="form-multiselect block w-full mt-1 multiple_select_height"
            multiple
          >
            <option
              v-for="software in hostSoftwares"
              :key="software.id"
              :value="software.id"
              >{{ software.name }}</option
            >
          </select>
        </label>
        <div v-if="installedSoftwares.length" class="px-4 py-3 sm:px-6">
          <ul
            role="list"
            class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-8 lg:gap-1"
          >
            <li
              v-for="softwareInstalled in installedSoftwares"
              :key="softwareInstalled.id"
              class="py-3 px-2 body__table--header text-center rounded-lg xl:px-2 xl:text-left"
            >
              <div class="space-y-6 xl:space-y-10">
                <div
                  class="space-y-0 xl:flex xl:items-center xl:justify-between"
                >
                  <div class="font-medium contact_card space-y-1">
                    <h3 class="text-white text-sm truncate">
                      {{ softwareInstalled.name }}
                    </h3>
                  </div>

                  <ul role="list" class="">
                    <li>
                      <a
                        @click="
                          detachSoftware(
                            softwareInstalled.id,
                            'Action successfull.'
                          )
                        "
                        href="javascript:void(0)"
                        :class="
                          `p-1 ml-1 shadow-sm border-2 border-white text-white rounded-md hover:bg-gray-400 cursor-pointer tooltip inline-flex`
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3 w-3"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          /></svg
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-end pb-2 pr-6">
        <!-- <button
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button> -->
        <button
          @click.prevent="attachSoftwares('Action successfull.')"
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md body__button text-white"
        >
          Save
        </button>
      </div>
    </div>
    <div
      :class="[
        activeBanner && isSuccess
          ? `border-green-400 border-2`
          : activeBanner && !isSuccess
          ? `border-red-400 border-2`
          : '',
        `header overflow-hidden shadow rounded-lg mt-5`,
      ]"
    >
      <div class="flex justify-between">
        <div
          class="text-lg leading-6 font-medium px-4 py-5 sm:px-6 body__header--text"
        >
          Network Interfaces
        </div>
        <div class="px-4 py-5 sm:px-6 flex-shrink-0">
          <button
            @click="openNewForm = !openNewForm"
            type="button"
            class="relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="px-4 py-5 sm:p-6" v-if="openNewForm">
        <div class="body__table--body shadow overflow-hidden sm:rounded-md">
          <NewNetworkInterface
            :id="hostId"
            @closeForm="openNewForm = false"
            @activeBanner="
              (activeBanner = true),
                getHost(this.hostId),
                (key = !key),
                (openNewForm = false)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
      </div>

      <div class="px-4 py-5 sm:p-6">
        <div class="body__table--body shadow overflow-hidden sm:rounded-md">
          <ul role="list" class="divide-y divide-gray-200">
            <li
              v-for="(network, idx) in hostNetworkInterfaces"
              :key="network.id"
            >
              <a href="javascript:void(0)" class="block hover:bg-gray-50">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div @click="openEditForm(idx)">
                      <p
                        class="text-sm font-medium body__table--body__text truncate"
                      >
                        {{ network.name }}
                      </p>
                    </div>
                    <div @click="openEditForm(idx)">
                      <p
                        class="text-sm font-medium body__table--body__text truncate"
                      >
                        {{ network.vlan }}
                      </p>
                    </div>
                    <div @click="openEditForm(idx)">
                      <p
                        class="text-sm font-medium body__table--body__text truncate"
                      >
                        {{ network.ipv4 }}
                      </p>
                    </div>
                    <div @click="openEditForm(idx)">
                      <p
                        class="text-sm font-medium body__table--body__text truncate"
                      >
                        {{ network.ipv6 }}
                      </p>
                    </div>
                    <div @click="openEditForm(idx)">
                      <p
                        class="text-sm font-medium body__table--body__text truncate"
                      >
                        {{ network.reverse }}
                      </p>
                    </div>
                    <div
                      class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                    >
                      <td class="pr-3 pl-3">
                        <div class="inline-flex">
                          <button
                            @click="openEditForm(idx)"
                            :class="
                              `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1 z-10`
                            "
                          >
                            <HoverTooltip text="Edit" />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                          <a
                            @click="
                              deleteNetworkInterface(
                                network.id,
                                'Interface deleted successfully.'
                              )
                            "
                            href="javascript:void(0)"
                            :class="
                              `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
                            "
                          >
                            <HoverTooltip text="Delete"/>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              /></svg
                          ></a>
                        </div>
                      </td>
                    </div>
                  </div>
                </div>
              </a>
              <div v-if="String(this.showIdx) === String(idx) && this.showEdit">
                <EditNetworkInterface
                  :id="this.hostId"
                  :networkId="network.id"
                  :name="network.name"
                  :vlan="network.vlan"
                  :ipv4="network.ipv4"
                  :ipv6="network.ipv6"
                  :reverse="network.reverse"
                  :active="network.active"
                  :comment="network.comment"
                  @closeForm="this.showEdit = false"
                  @activeBanner="
                    (activeBanner = true),
                      getHost(this.hostId),
                      (key = !key),
                      (this.showEdit = false)
                  "
                  @successMsg="successMsg = $event"
                  @success="isSuccess = $event"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import NewNetworkInterface from "../../components/hosting/NewNetworkInterface.vue";
import EditNetworkInterface from "../../components/hosting/EditNetworkInterface.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import HoverTooltip from "../../components/HoverTooltip.vue";
import { Switch } from "@headlessui/vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  title() {
    return `Host - ${localStorage.getItem("title")}`;
  },
  props: ["hostId"],
  data() {
    return {
      isLoading: true,
      fullPage: false,
      showForm: true,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      host: {},
      infrastructures: [],
      providers: [],
      nics: [],
      dataCenters: [],
      serviceTypes: [],
      hostTypes: [],
      hostRoles: [],
      hostFunctions: [],
      shellTypes: [],
      operationalSystems: [],
      customerId: null,
      installedSoftwares: [],
      hostSoftwares: [],
      addSoftwares: [],
      hostNetworkInterfaces: [],
      showIdx: null,
      showEdit: false,
      openNewForm: false,
      form: {
        client: "",
        name: "",
        code: "",
        address: "",
        vlan: null,
        config: null,
        infrastructure: null,
        provider: null,
        nicId: "",
        shi: "",
        dataCenter: null,
        name: "",
        serviceType: 0,
        hostType: 0,
        hostRole: 0,
        function: null,
        shellType: 0,
        operationalSystem: 0,
        installation: null,
        outsourced: 0,
      },
    };
  },
  components: {
    Switch,
    SuccessBanner,
    HoverTooltip,
    NewNetworkInterface,
    EditNetworkInterface,
    Loading,
  },
  methods: {
    openEditForm(idx) {
      if (this.showIdx !== "" && idx != this.showIdx && this.showEdit) {
        this.showIdx = idx;
        this.showEdit = true;
      } else {
        this.showEdit = !this.showEdit;
        this.showIdx = idx;
      }
    },
    async onSubmit(msg) {
      let data = {
        customer_id: this.customerId,
        code: this.form.code,
        name: this.form.name,
        address: this.form.address,
        vlan: this.form.vlan,
        host_os_id: this.form.operationalSystem,
        host_role_id: this.form.hostRole,
        host_service_id: this.form.serviceType,
        host_shell_id: this.form.shellType,
        host_type_id: this.form.hostType,
        host_function_id: this.form.function,
        host_supplier_id: this.form.provider,
        managed: this.form.outsourced,
        config: this.form.config,
        infrastructure_id: this.form.infrastructure,
        supplier_identification: null,
        datacenter_id: this.form.dataCenter,
        comment: "string",
        installed_at: this.form.installation,
        deleted_at: null,
      };
      console.log(data);
      try {
        const res = await axios.put(
          `${this.$cookie.getCookie("API")}/api/v1/hosts/${this.hostId}`,
          data
        );
        console.log(res);
        if (res) {
          window.scrollTo(0, 0);
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = msg;
          this.isSuccess = true;
        }
      } catch (error) {
        window.scrollTo(0, 0);
        this.activeBanner = true;
        this.key = !this.key;
        this.successMsg = "Something wrong happened.";
        this.isSuccess = false;
        this.errorHandling(error);
      }
    },
    attachSoftwares(msg) {
      let data = {
        hostSoftwares: this.addSoftwares,
      };
      axios
        .post(
          `${this.$cookie.getCookie("API")}/api/v1/hosts/${
            this.hostId
          }/softAttach`,
          data
        )
        .then((res) => {
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = msg;
          this.isSuccess = true;
          this.getHost(this.hostId);
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = "Something wrong happened.";
          this.isSuccess = false;
          this.errorHandling(error);
        });
    },
    detachSoftware(id, msg) {
      let data = {
        hostSoftwares: [id],
      };
      axios
        .post(
          `${this.$cookie.getCookie("API")}/api/v1/hosts/${
            this.hostId
          }/softDetach`,
          data
        )
        .then((res) => {
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = msg;
          this.isSuccess = true;
          this.getHost(this.hostId);
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = "Something wrong happened.";
          this.isSuccess = false;
          this.errorHandling(error);
        });
    },
    deleteNetworkInterface(id, msg) {
      axios
        .delete(
          `${this.$cookie.getCookie("API")}/api/v1/hostNetworkInterfaces/${id}`
        )
        .then((res) => {
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = msg;
          this.isSuccess = true;
          this.getHost(this.hostId);
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = "Something wrong happened.";
          this.isSuccess = false;
          this.errorHandling(error);
        });
    },
    async getHost(id) {
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hosts/${id}`
        );
        // const customer = await this.getCustomer(res.data.customer_id);
        // const hostRole = await this.getRole(res.data.host_role_id);
        // const hostType = await this.getType(res.data.host_type_id);
        // const hostShell = await this.getShell(res.data.host_shell_id);
        // const hostOS = await this.getOS(res.data.host_os_id);
        this.host = res.data;
        this.installedSoftwares = res.data.host_host_softwares;
        this.hostNetworkInterfaces = res.data.host_network_interfaces;
        console.log(res.data);
        this.form.name = res.data.name;
        this.form.code = res.data.code;
        this.form.outsourced = res.data.managed;
        this.form.vlan = res.data.vlan;
        this.form.address = res.data.address;
        this.form.installation = new Date(
          res.data.installed_at
        ).toLocaleDateString();
        this.customerId = res.data.customer_id;
        this.form.client = res.data.customer.name;
        this.form.hostRole = res.data.host_role;
        this.form.hostType = res.data.host_type;
        this.form.shellType = res.data.host_shell;
        this.form.operationalSystem = res.data.host_os;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.errorHandling(error);
      }
    },
    async getCustomer(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/customers/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getRole(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostRoles/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getType(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostTypes/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getShell(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostShells/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getOS(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostOs/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getInfrastructures() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/infrastructures`
        );
        this.infrastructures = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getFunctions() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostFunctions`
        );
        this.hostFunctions = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getOperationalSystems() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostOs`
        );
        this.operationalSystems = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getDatacenters() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/datacenters`
        );
        this.dataCenters = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getServices() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostServices`
        );
        this.serviceTypes = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostRoles() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostRoles`
        );
        this.hostRoles = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostTypes() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostTypes`
        );
        this.hostTypes = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostSoftwares() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostSoftwares`
        );
        this.hostSoftwares = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostProviders() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostSuppliers`
        );
        this.providers = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
  },
  mounted() {
    this.getHost(this.hostId);
    this.getInfrastructures();
    this.getFunctions();
    this.getOperationalSystems();
    this.getDatacenters();
    this.getServices();
    this.getHostRoles();
    this.getHostTypes();
    this.getHostSoftwares();
    this.getHostProviders();
  },
};
</script>

<style>
.multiple_select_height {
  height: 200px;
}
</style>

<template>
  <div class="z-20 shadow">
    <div class="add_destination_form">
      <div class="w-full">
        <form
          @submit.prevent="onSubmit(this.id, 'Action successfull.')"
          class="shadow space-y-8 divide-y divide-gray-200 form_padding"
          method="POST"
        >
          <div class="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3
                  class="text-lg leading-6 font-medium body__header--text capitalize"
                >
                  New network interface
                </h3>
              </div>
              <br />
              <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
                <div class="space-y-6 sm:space-y-5">
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="name"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Name
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.name"
                        required=""
                        type="text"
                        name="name"
                        id="name"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                        :placeholder="this.origin"
                      />
                    </div>
                  </div>

                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="vlan"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      VLAN
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.vlan"
                        type="text"
                        name="vlan"
                        id="vlan"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="ipv4"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      IPV4
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.ipv4"
                        type="text"
                        name="ipv4"
                        id="ipv4"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="ipv6"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      IPV6
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.ipv6"
                        type="text"
                        name="ipv6"
                        id="ipv6"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="reverse"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Reverse
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.reverse"
                        type="text"
                        name="reverse"
                        id="reverse"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="comment"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Comment
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.comment"
                        type="text"
                        name="comment"
                        id="comment"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="outsourced"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                    >
                      active
                    </label>
                    <Switch
                      id="outsourced"
                      v-model="form.active"
                      class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
                    >
                      <span
                        aria-hidden="true"
                        class="pointer-events-none absolute bg-white w-full h-full rounded-md"
                      />
                      <span
                        aria-hidden="true"
                        :class="[
                          form.active ? 'body__button' : 'bg-gray-200',
                          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
                        ]"
                      />
                      <span
                        aria-hidden="true"
                        :class="[
                          form.active ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
                        ]"
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <a
                href="javascript:void(0)"
                @click="$emit('closeForm')"
                class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {{ $t("ticket.cancel") }}
              </a>
              <button
                type="submit"
                :class="
                  `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`
                "
              >
                {{ $t("ticket.send") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Switch } from "@headlessui/vue";

export default {
  props: ["id"],
  components: { Switch },
  data() {
    return {
      form: {
        name: "",
        vlan: "",
        ipv4: "",
        ipv6: "",
        reverse: "",
        active: 0,
        comment: "",
      },
    };
  },
  methods: {
    async onSubmit(id, msg) {
      let data = {
        host_id: id,
        name: this.form.name,
        vlan: this.form.vlan,
        ipv4: this.form.ipv4,
        ipv6: this.form.ipv6,
        reverse: this.form.reverse,
        active: this.form.active,
        comment: this.form.comment,
      };
      console.log(data);
      axios
        .post(
          `${this.$cookie.getCookie("API")}/api/v1/hostNetworkInterfaces`,
          data
        )
        .then((res) => {
          console.log(res);
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.errorHandling(error);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", this.errorDataResponse);
        });
    },
  },
};
</script>

<style></style>
